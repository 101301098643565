<template>
    <div>
        <svg viewBox="0 0 204 204" fill="none" xmlns="http://www.w3.org/2000/svg" class="circle circle1">
            <circle cx="102" cy="102" r="101.5" stroke="#268976" />
            <circle cx="102.5" cy="101.5" r="35.5" fill="#01493B" class="inner_circle" />
        </svg>
        <svg viewBox="0 0 204 204" fill="none" xmlns="http://www.w3.org/2000/svg" class="circle circle2">
            <circle cx="102" cy="102" r="101.5" stroke="#268976" />
            <circle cx="102.5" cy="101.5" r="35.5" fill="#01493B" class="inner_circle" />
        </svg>
        <svg viewBox="0 0 204 204" fill="none" xmlns="http://www.w3.org/2000/svg" class="circle circle3">
            <circle cx="102" cy="102" r="101.5" stroke="#268976" />
            <circle cx="102.5" cy="101.5" r="35.5" fill="#01493B" class="inner_circle" />
        </svg>
        <Intro />
        <AboutMe />
        <Works />
        <Contact />

        <div class="caret" @click="goBackUp" v-if="showCaret" v-scroll-reveal.reset>
            <i class="fas fa-angle-up"></i>
        </div>
    </div>
</template>

<script>
const AboutMe = () => import('@/views/AboutMe')
const Works = () => import('@/views/Works')
const Contact = () => import('@/views/Contact')
const Intro = () => import('@/views/Intro')
import { Intersection_observer } from '@/utils/observer'
export default {
    metaInfo: {
        // Children can override the title.
        title: 'Nifemi Alpine',
        // Result: My Page Title ← My Site
        // If a child changes the title to "My Other Page Title",
        // it will become: My Other Page Title ← My Site
        titleTemplate: '%s → My Personal Portfolio ',
        // Define meta tags here.
        meta: [
            { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
            {
                name: 'description',
                content:
                    'I am a Senior Software Engineer and Lead DevOps Engineer with over 5 years of experience and a track record in cybersecurity. Tech Stacks: PHP/Laravel, Python, Java, Javascript/Vue & React, C# & Go.'
            }
        ]
    },
    components: {
        AboutMe,
        Works,
        Contact,
        Intro
    },
    data() {
        return {
            showCaret: false
        }
    },
    mounted() {
        document.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            const data = this.$el.querySelector('#intro').getBoundingClientRect()
            this.showCaret = false
            if (data.top <= -1 * data.height) {
                this.showCaret = true
            }
        },
        goBackUp() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    },

    computed: {
        element: function() {
            return this.$el.querySelector('#contactMe')
        }
    }
}
</script>

<style lang="scss" scoped>
.circle {
    width: 20%;
    height: 20%;
    position: absolute;
    z-index: -1;
}

@media screen and (max-width: 500px) {
    .circle {
        width: 30%;
        height: 30%;
    }

    .circle1 {
        top: 10% !important;
    }

    .circle2 {
        bottom: -15% !important;
        left: 35% !important;
    }
}

.circle1 {
    top: 30%;
    left: -5%;
    .inner_circle {
        animation: circleAnimation 2s ease-in-out 1 alternate-reverse both;
    }
}

.circle2 {
    bottom: -10%;
    left: 40%;
    .inner_circle {
        animation: circleAnimation 2s ease-in-out 0.8s 1 alternate-reverse both;
    }
}

.circle3 {
    top: 50%;
    right: 0;
    .inner_circle {
        animation: circleAnimation 2s ease-in-out 0.4s 1 alternate-reverse both;
    }
    // z-index: -2;
}

.caret {
    position: fixed;
    z-index: 999;
    bottom: 8%;
    right: 5%;
    background: $secondary;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    width: 25px;
    height: 25px;
    box-shadow: 0px 0px 10px rgba(10, 236, 199, 0.9);
    transition: 0.5s;
    &:hover {
        box-shadow: 0px 0px 40px rgba(10, 236, 199, 0.9);
    }
    i {
        font-size: 24px;
        color: white;
    }
}
</style>
